
<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You added a class.</span>
      <v-btn text color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="dialog">
      <template v-slot:activator="{ on }">
        <v-btn fab bottom right color="primary" fixed x-large v-on="on">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-alert v-if="error" type="error">
                <ul class="alert alert-danger">
                  <li v-for="(errorD, index) in error.details" :key="index">{{ errorD[0] }}</li>
                </ul>
              </v-alert>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-select v-model="editedItem.level_id" hide-details="auto" :items="levels" :label="$t('level')" :rules="[rules.required]" item-text="name" item-value="id"></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select v-model="editedItem.schedule_id" :items="schedules" hide-details="auto" :label="$t('schedule')" :rules="[rules.required]" item-text="day" item-value="id"></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select v-model="editedItem.teacher_id" :items="teachers" hide-details="auto" :label="$t('teacher')" :rules="[rules.required]" item-text="name" item-value="id"></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select v-model="editedItem.classroom_id" :items="classrooms" hide-details="auto" :label="$t('classroom')" :rules="[rules.required]" item-text="name" item-value="id"></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.max_students" label="Nombre max d'élèves" hide-details="auto" :rules="[rules.required]"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">{{$t('actions.cancel')}}</v-btn>
          <v-btn color="blue darken-1" text @click="save">{{$t('actions.save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tabs grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="i in schedules" :key="i.id" :href="`#tab-${i.id}`">{{ i.day }}</v-tab>
      <v-tab-item v-for="i in schedules" :key="i.id" :value="'tab-' + i.id">
        <v-row v-if="classes">
          <v-card :loading="loading" class="ma-10 mb-5 col-md-3" max-height="1000" v-for="c in classesFiltered(i)" :key="c.id" outlined>
            <!--  <v-sheet class="mt-n12 mx-auto mb-5 tableau" color="green" elevation="0" max-width="80%" height="25%">
              <h3 class="pt-2">{{c.level}}</h3>
              <div class="text-right pr-2">{{c.teacher}}</div>
            </v-sheet>-->
            <v-card-title>
              <span class>{{c.level}}</span>
              <v-spacer></v-spacer>

              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item :to="goToDetailClasse(c)">
                    <v-list-item-title>Afficher</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-subtitle>{{c.teacher}}</v-card-subtitle>

            <v-progress-linear color="blue" height="20" striped :value="remplissage(c)">
              <strong>{{c.nb_students}}/{{c.max_students}}</strong>
            </v-progress-linear>
            <v-card-text>{{c.classroom}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="editItem(c)">{{$t('actions.edit')}}</v-btn>
              <v-btn color="blue darken-1" disabled text @click="deleteItem(c)">{{$t('actions.delete')}}</v-btn>
            </v-card-actions>
          </v-card>
          <!--  <v-card :loading="loading" class="ma-12 col-md-3 text-center pb-12" max-height="1000" v-for="c in classesFiltered(i)" :key="c.id" outlined>
            <v-sheet class="mt-n12 mx-auto mb-5 tableau" color="green" elevation="0" max-width="80%" height="25%">
              <h3 class="pt-2">{{c.level}}</h3>
              <div class="text-right pr-2">{{c.teacher}}</div>
            </v-sheet>
            <v-avatar class="mx-5 my-5" color="red" v-for="s in c.nb_students" :key="'toto'+s"></v-avatar>
            <v-avatar class="mx-5 my-5" color="teal" v-for="s in filteredArray(c)" :key="s"></v-avatar>
            <v-card-text class="text-right">{{c.classroom}}</v-card-text>
          </v-card>-->
        </v-row>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { APIServices } from "@/api/api";
export default {
  data() {
    return {
      classes: null,
      students: null,
      loading: false,
      schedules: null,
      teachers: null,
      classrooms: null,
      levels: null,
      snackbar: false,
      editedIndex: -1,
      editedItem: {
        level: null,
        classroom: null,
        teacher: null,
        max_students: 0,
        schedule: null
      },
      defaultItem: {
        level: null,
        classroom: null,
        teacher: null,
        max_students: 0,
        schedule: null
      },
      dialog: false,
      rules: {
        required: value => !!value || "Champs obligatoire"
      },
      nameRules: [value => !!value || "Champs obligatoire"],
      error: null,
      errors: []
    };
  },
  created() {
    this.loading = true;
    this.loadClasses();
    this.loadSchedules();

    this.loadLevels();
    this.loadTeachers();
    this.loadClassrooms();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Ajout d'une classe" : "Modification d'une classe";
    }
  },
  methods: {
    goToDetailClasse(classe){
      return "/school/1/class/"+classe.id;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
      this.file = null;
      this.error = null;
    },

    clear() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    save() {
      this.loading = true;
      console.log(this.editedIndex);
      if (this.editedIndex > -1) {
        console.log("Mode edition");
        APIServices.put(`/classes/` + this.editedItem.id, this.editedItem)
          .then(response => {
            this.snackbar = true;
            console.log(response.data);
            //this.tutor = response.data;
            //Object.assign(this.classes[this.editedIndex], this.editedItem);
            this.loadClasses();
            this.loading = false;
            this.close();
          })
          .catch(e => {
            this.error = e.response.data;
            console.log(e.response.data);
            this.loading = false;
          });
      } else {
        console.log("Mode ajout");

        APIServices.post(`/classes`, this.editedItem)
          .then(response => {
            this.snackbar = true;
            console.log(response);
            //this.tutor = response.data;
            this.loadClasses();
            this.loading = false;
            this.close();
          })
          .catch(e => {
            this.error = e.response.data;
            console.log(e.response.data);
            this.loading = false;
          });
      }
    },
    editItem(item) {
      console.log(item);
      this.editedIndex = this.classes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      confirm("Are you sure you want to delete this item " + item.level + " ?");
    },
    classesFiltered(s) {
      return this.classes.filter(item => {
        // Many more calculations
        if (item.schedule === s.day) {
          return true;
        } else {
          return false;
        }
      });
    },
    remplissage(c) {
      return (c.nb_students / c.max_students) * 100;
    },
    filteredArray(c) {
      return c.max_students - c.nb_students;
    },
    loadClassrooms() {
      console.log("loading classrooms");
      this.loading = true;
      APIServices.get("/classrooms")
        .then(response => {
          this.classrooms = response.data;
          console.log(response);
          this.loading = false;
        })
        .catch(e => {
          this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    loadTeachers() {
      console.log("loading teachers");
      this.loading = true;
      APIServices.get("/teachers?all")
        .then(response => {
          this.teachers = response.data;
          console.log(response);
          this.loading = false;
        })
        .catch(e => {
          this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    loadLevels() {
      console.log("loading levels");
      this.loading = true;
      APIServices.get("/levels")
        .then(response => {
          this.levels = response.data;
          console.log(response);
          this.loading = false;
        })
        .catch(e => {
          this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    loadSchedules() {
      console.log("loading schedules");
      this.loading = true;
      APIServices.get("/schedules")
        .then(response => {
          this.schedules = response.data;
          console.log(response);
          this.loading = false;
        })
        .catch(e => {
          this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    loadClasses() {
      console.log("loading classes");
      this.loading = true;
      APIServices.get("/classes")
        .then(response => {
          this.classes = response.data;
          console.log(response);
          this.loading = false;
        })
        .catch(e => {
          this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    }
  }
};
</script>

<style>
.v-application .tableau {
  border: 5px solid brown !important;
}
</style>
